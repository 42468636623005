body {
  margin: 0px;
}

.basion-gui__table-spinner {
  margin: auto;
  margin-top: 40px;
  width: -moz-fit-content;
  width: fit-content;
}

.menu__sick_logo {
  max-height: 4rem;
  margin-right: 1rem;
}
